body {
  font-family: 'Open Sans';
  color: #000000;
}

.bg-top {
  background-color: black;
}

.bg-nav {
  background: #FFBD22;
}

.logo{
  max-width:220px;
}

.bg-nav .web-nav a {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-decoration: none;
}

.bg-nav .web-nav button {
  background: #000000;
}

.balance{
  background-color:#FFBD22 !important;
  padding:1px 3px;
  border: solid 2px #000;
  border-radius:5px;
}

.bg-yellow {
  background: #FFBD22;
  border: solid 1.5px #000;
}

.mint-box {
  /*border: 9px solid #000000;*/
  box-sizing: border-box;
}

.landing-1 {
  background: url('./img/landing-bg-1.png') no-repeat center center/cover;
  padding-top:150px !important;
  height: 100vh;
}

.landing-6{
  background:url('./img/landing-bee-bg.png') no-repeat center center/cover;
  height:100vh;
}

.para{
  background-attachment: fixed !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.para .container-fluid {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  /* Add any other styles you need */
}

.black-btn {
  background: #000000;
  color: #FFBD22;
  font-weight: 700;
}
.black-btn:hover {
  color: #FFBD22;
}

.mint-controller:hover {
  cursor: pointer;
}

.yellow-btn {
  background: #FFBD22;
}

.landing-3 {
  background: url('./img/landing-bg-2.png') no-repeat center center/cover;
  padding-top:100px;
  padding-bottom:50px;
}

.hero-dynamic-img {
  height: 200px;
}

@media (max-width: 426px) {
  .landing-3 img {
    width: 100px;
  }
}

.bg-black {
  background-color: #000000;
}

.footer a {
  text-decoration: none;
}

@media (max-width: 600px) {
  .footer .d-inline-block {
    display: block !important;
  }
}

.mobile-nav {
  display: none;
}

@media (max-width: 769px) {
  .web-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  background-color: #000000;
  left: -100px;
}

.dropdown-item {
  color: #FFBD22;
}

.dropdown-item a {
  color: #FFBD22;
}

.h0 {
  font-size: 45px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 40px;
}

.h1 {
  font-size: 55px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 59px;
}

.h3 {
  font-size: 45px;
  font-weight: 800 !important;
  font-style: normal !important;
}

.h4 {
  font-size: 24px;
  font-weight: 700 !important;
  font-style: normal;
  line-height: 32px;
}

.season.h3{
  margin-bottom:0px !important;
  font-size:40px !important;
}

.season-slogan {
  line-height: 0;
}

@media (max-width: 769px) {
  .h0 {
    font-size: 40px !important;
    font-style: normal !important;
    font-weight: 800 !important;
  }

  .h1 {
    font-size: 45px !important;
    font-style: normal !important;
    font-weight: 800 !important;
  }
}

.beehive {
  background: url('./img/beehive-bg.png') no-repeat center center/cover;
  background-attachment: fixed;
  padding-top: 140px;
  padding-bottom: 110px;
}

@media (max-width: 769px) {
  .beehive .col-md-10 {
    text-align: center !important;
  }

  .beehive img {
    width: 130px;
  }
}

.honeyjar {
  background: url('./img/honeyjar-bg.png') no-repeat center center/cover;
  padding-top: 140px;
}

.honeyjar .honeyjar-box {
  border: 6px solid #000;
  background-color: #F7F7F7;
}

.honeyjar input {
  background-color: transparent;
  border: none !important;
  outline: none !important;
}

.honeyjar input:focus {
  border: none !important;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.border {
  border-color: #000 !important;
}

.honeyjar .honeyjar-box img {
  width: 50px;
  height: 50px;
}

@media (max-width: 769px) {
  .honeyjar .honeyjar-box img {
    width: 35px;
    height: 35px;
  }
}

.metamorphosis {
  background: url('./img/meta-bg.png') no-repeat center center/cover;
  padding-top: 120px;
}

@media (max-width: 769px) {
  .metamorphosis .py-4 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .metamorphosis .py-5 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.honey-balance {
  display: inline-flex;
  float: right;
  padding: 0.375rem 0.75rem;
}

.swap-input {
  width: 70%;
  text-align: left;
}

.swap-symbol {
  width: 30%;
  text-align: center;
}

.btn-max {
  cursor: pointer;
}

.collect-spin {
  padding: 0.4rem;
  width: 1rem !important;
  height: 1rem !important;
}

.bee-image {
  width: 200px;
} 

.text-left-justify{
  text-align: left !important;
}

/*
.rarity-color .text-success:nth-of-type(1) p, .rarity-color .text-success:nth-of-type(2) p, .rarity-color .text-success:nth-of-type(3) p {
  color: green !important;
}


.rarity-color:nth-of-type(4), .text-success .rarity-color:nth-of-type(5), .rarity-color:nth-of-type(6) p{
  color: blue !important;
}

.text-success:nth-of-type(7){
  color: purple !important;
}
*/
.rarity-common{
  color: green !important;
}

.rarity-rare{
  color: blue !important;
}

.rarity-epic{
  color: #e905e9 !important;
}

.top-navbar{
  position: fixed;
  width: 100%;
  z-index: 999;
}

.landing-2{
  padding-top:50px;
  padding-bottom:50px;
}

.landing-4 .row{
  padding-top:100px;
  padding-bottom:100px;
  max-width: 1040px;
  margin: auto;
}

.FAQ {
  padding-top: 40px;
  padding-bottom: 60px;
}

.bg-nav{
  /*box-shadow: 5px 5px 5px #4d4d4d78;*/
  background: url(./img/Honey-Bee-Drip.png) no-repeat 50%/cover;
  min-height: 143px;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, .6));
}

.tokenomics{
  padding-top: 140px;
}

.landing-4{
  background-color:black;
}

.landing-4 .col-lg-12{
  color:#FFBD22;
}

.pb7{
  padding-bottom:7px;
}

/*bee animation*/
.bee-image {
animation: bob 1s ease-in-out infinite alternate;
}

@keyframes bob {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}

.buttonBob{
  animation: bob 1s ease-in-out infinite alternate;
}

/*collect button animation*/
.collect-btn {
  animation: collect-flash 1s ease-in-out infinite alternate;
  }
  
  @keyframes collect-flash {
    0% {
      background-color: #FFBD22;
    }
    100% {
      background-color: #f7931e;
    }
  }

  .text-secondary{
    background-color:#d1d1d1;
  }

  .honey-hive-top{
    background-image:url(./img/honey-home-hive.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
    max-width: 720px;
  }

  .honey-hive-sign{
    background-image:url(./img/honey-hive-sign.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height:200px;
    padding:40px 0px 80px 0px;
  }

  .bee-preview{
    padding-bottom:40px;
  }

  @media (max-width: 769px) {
    .honey-hive-sign{
      padding: 20px 0px 40px 0px;
    }
    .bee-preview{
      padding-bottom:inherit;
    }
    .mint-amount-box .h3{
      font-size:16px !important;
    }
  
    .mint-amount-box .h4{
      font-size:14px;
      padding-top:0px !important;
    }
    .mint-amount-box{
      margin-top:0px !important;
      margin-bottom:0px !important;
      padding-top:10px !important;
      padding-bottom: 90px !important;
    }

    .mint-amount-box .black-btn{
      margin:0px !important;
      padding-top: .5rem!important;    }
  }

  .mint-amount-box{
    max-width:500px;
    margin:auto;
  }

  .mint-amount-box .h3{
    font-size:25px;
  }

  .mint-amount-box .h4{
    line-height:15px;
  }

.honey-hive-sign .h0{
  text-shadow: -3px -1px 2px #000000bf, 0 -2px 2px #000000c2;
  color: #a9672f;
  mix-blend-mode: multiply;
}

.honey-hive-sign .h3{
  text-shadow: -2px -2px 2px #000000c9, 0 -2px 2px #0000008f;
  color: #a9672f;
  mix-blend-mode: multiply;
}

.honey-hive-sign .h4{
  text-shadow: -2px -1px 2px #00000070, 0px -1px 1px #0000005e;
  color: #f7931e;
  mix-blend-mode: hard-light;
}

.mint-box .h0::after{
  color: #fff;
}

.honey-top.row{
display:inherit;
}
